import React, { useState, useEffect } from "react";
import place from "../images/place.png";
import phone from "../images/phone.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCart,
  getCart,
  placeOrder,
} from "../features/restaurant/cartSlice";
import { selectPrimaryAddress } from "../features/restaurant/addressSlice";
import { Dialog, Label, Pane, Spinner, Textarea } from "evergreen-ui";
import {
  notLoggedInErrorMessage,
  notLoggedInErrorTitle,
  removePopupState,
  setPageUrl,
  getToken,
} from "../services/standard";
import { useNavigate } from "react-router-dom";
import { toasterService, toasterType } from "../services/toasterService";
import Product from "../components/Product";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import visa from "../images/visa.webp";
import mastercard from "../images/logo-mastercard.svg";
import cash from "../images/logo-cash.svg";

const Cart = () => {
  const navigate = useNavigate();
  const selectCartApiData = useSelector(selectCart);
  const selectCartApi = selectCartApiData?.result;
  const selectPrimaryAddressApiData = useSelector(selectPrimaryAddress);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData.result;
  const address = selectPrimaryAddressApi.data;

  const [transactionType, setTransactionType] = useState("");
  const [transactionTypeError, setTransactionTypeError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPlaceOrder, setIsPlaceOrder] = useState(false);
  const dispatch = useDispatch();
  const token = getToken();
  const [commentsArray, setCommentsArray] = useState([]);

  useEffect(() => {
    removePopupState();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const forId = currentURL.split("/").slice(-1)[0];
    if (forId === "") {
      const urlCurrentPage = ``;
      setPageUrl(urlCurrentPage);
    } else {
      const urlCurrentPage = `${forId}`;
      setPageUrl(urlCurrentPage);
    }
    if (token && token?.length > 0) {
      setLoading(true);
      dispatch(getCart());
    } else {
      setLoading(false);
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (token && token?.length > 0) {
      if (
        selectCartApiData?.status === "complete" &&
        selectPrimaryAddressApiData?.status === "complete"
      ) {
        setLoading(false);
        setTimeout(() => setIsPlaceOrder(false), 3000);
      }
    } else {
      setLoading(false);
    }
  }, [token, selectCartApiData?.status, selectPrimaryAddressApiData?.status]);

  useEffect(() => {
    if (token && token?.length > 0) {
      if (selectCartApiData?.status === "complete") {
        selectCartApiData?.result?.data?.cart_items?.forEach((cart_item) => {
          updateComment(cart_item.product_id, "");
        });
      }
    }
  }, [token, selectCartApiData?.status]);

  async function postPlaceOrder() {
    if (transactionType === "") {
      setTransactionTypeError(true);
    } else {
      setIsPlaceOrder(true);
      await setLoading(true);
      if ("address" in address) {
        await dispatch(
          placeOrder({
            address_id: address.address[0]._id,
            additional_comments: commentsArray,
            transaction_type: transactionType,
          })
        );
        await dispatch(getCart());
        await dispatch(getCartCountASyncThunk());
      }
    }
  }

  const getComments = (commentsArrayLocal, id) => {
    if (commentsArrayLocal) {
      let index = commentsArrayLocal?.findIndex((x) => x._id === id);
      if (index !== -1) {
        let temporaryArray = commentsArrayLocal?.slice();
        return temporaryArray[index][commentsArrayLocal];
      }
    }
  };

  const updateComment = (id, newValue) => {
    let index = commentsArray.findIndex((x) => x._id === id);
    if (index !== -1) {
      let temporaryArray = commentsArray.slice();
      temporaryArray[index]["comments"] = newValue;
      setCommentsArray(temporaryArray);
    } else {
      let temporaryArray = commentsArray;
      temporaryArray.push({ _id: id, comments: "" });
      setCommentsArray(temporaryArray);
    }
  };

  const gotoAddAddress = () => {
    navigate(`/addaddress`);
  };
  return (
    <div>
      <div className="bg-white pt-5 mt-5 page-min-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 top-margin">
              {isLoading ? (
                <Pane>
                  <Spinner marginX="auto" marginY={120} />
                </Pane>
              ) : !token ? (
                <Pane>
                  <Dialog
                    isShown={true}
                    intent="success"
                    title={notLoggedInErrorTitle}
                    hasClose={false}
                    onCancel={(close) => {
                      navigate("/");
                    }}
                    cancelLabel="Back to home"
                    confirmLabel="Login"
                    onConfirm={(close) => {
                      navigate("/sign-in");
                    }}
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                  >
                    <span className="fs-6">{notLoggedInErrorMessage}</span>
                  </Dialog>
                </Pane>
              ) : (
                <div className="bg-white">
                  {/* todo: to show coupon codes */}
                  {/* <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 top-margin">
                        <div className="card offer-card">
                          <div className="card-body save-on-delivery">
                            <div className="row">
                              <div className="col-lg-3 col-md-5">
                                <img
                                  alt=""
                                  className="edr-sod-tag image-fluid"
                                  src={save_on_delivery}
                                />
                              </div>
                              <div className="col-lg-5 col-md-5">
                                <h2 className="text-edr-secondary fw-bold mt-3">
                                  Save on delivery
                                </h2>
                                <h6 className="text-black">
                                  Get $20 off on every order
                                </h6>
                              </div>
                              <div className="col-lg-4">
                                <div className="offer-code mt-5">
                                  <div>
                                    <h4 className="text-center fw-bold mt-2">
                                      Offer code: EATFIRST
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="container-fluid mb-5">
                    <div className="row">
                      <div className="col-md-12">
                        <h4>Checkout</h4>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    {"cart_items" in selectCartApi?.data &&
                    selectCartApi?.data?.cart_items.length > 0 ? (
                      <div
                        className="row justify-content-evenly"
                        style={{ paddingBottom: "20px" }}
                      >
                        <div className="col-lg-6">
                          <div className="row pb-5">
                            <div className="card edr-card-radius">
                              <div className="row">
                                <div className="flex-row cartProductsList">
                                  {"cart_items" in selectCartApi?.data
                                    ? selectCartApi?.data?.cart_items.map(
                                        (products, key) => {
                                          return (
                                            <>
                                              <Product
                                                key={key}
                                                productName={
                                                  products?.product_name
                                                }
                                                price={products?.price}
                                                offerPrice={
                                                  products?.final_price
                                                }
                                                productId={products?.product_id}
                                                quantity={products?.quantity}
                                                shopId={
                                                  selectCartApi?.data?.shop?._id
                                                }
                                                cartQuantity={
                                                  products?.quantity
                                                }
                                                outOfStock={
                                                  products?.out_of_stock
                                                }
                                                isOpen={
                                                  selectCartApi?.data?.shop
                                                    ?.is_open
                                                }
                                                discount={products?.discount}
                                                discountType={
                                                  products?.discount_type
                                                }
                                                description={
                                                  products?.product_description
                                                }
                                                cartId={selectCartApi.data?._id}
                                                shopName={
                                                  selectCartApi?.data?.shop.name
                                                }
                                                productImage={
                                                  products?.product_image
                                                }
                                                cardType={"cart"}
                                                isDeliverable={
                                                  selectCartApi.data
                                                    ?.isDeliverable
                                                }
                                                discounted={products?.discount}
                                                distance={
                                                  selectCartApi.data?.distance
                                                }
                                              />
                                              <div
                                                className="row"
                                                key={products?.product_id}
                                              >
                                                <Pane>
                                                  <Label
                                                    htmlFor="textarea-2"
                                                    marginBottom={4}
                                                    display="block"
                                                  >
                                                    {/* <h6>Additional Comments</h6> */}
                                                  </Label>
                                                  <Textarea
                                                    id="textarea-2"
                                                    className="mb-3 fs-6"
                                                    onChange={(e) =>
                                                      updateComment(
                                                        products?.product_id,
                                                        e.target.value
                                                      )
                                                    }
                                                    value={getComments(
                                                      commentsArray,
                                                      products?.product_id
                                                    )}
                                                    placeholder="Order comments"
                                                  />
                                                </Pane>
                                                <hr
                                                  className="my-2"
                                                  style={{ width: "100%" }}
                                                />
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                                <hr
                                  className="mt-4 mb-1"
                                  style={{ width: "100%" }}
                                />
                                {/* todo:// promo code system */}
                                {/* <div className="row">
                                  <div className="col-md-12">
                                    <form>
                                      <div className="buttonIn mt-5">
                                        <input
                                          className="searchbox"
                                          type="text"
                                          placeholder="Offer code"
                                        />
                                        <button className="btn btn-successs btn-small  bg-edr-yellow text-white search-btn fw-bold">
                                          APPLY
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div> */}
                                <div className="row mt-4">
                                  <div className="col-7">
                                    <h6>Amount (EXCL. VAT)</h6>
                                  </div>
                                  <div className="col-5">
                                    <h6 className="text-edr-secondary float-end">
                                      AED{" "}
                                      <span className="fw-bold">
                                        {selectCartApi?.data?.original_price}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-7">
                                    <h6>VAT @ 5%</h6>
                                  </div>
                                  <div className="col-5">
                                    <h6 className="text-edr-secondary float-end">
                                      AED{" "}
                                      <span className="fw-bold">
                                        {selectCartApi?.data?.vat}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-7">
                                    <h6>Delivery fees</h6>
                                  </div>
                                  <div className="col-5">
                                    <h6 className="text-edr-secondary float-end">
                                      AED{" "}
                                      <span className="fw-bold">
                                        {selectCartApi?.data?.delivery_charge ??
                                          0}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                  <div className="col-7">
                                    <h6>Total Amount</h6>
                                  </div>
                                  <div className="col-5">
                                    <h6 className="text-edr-secondary float-end">
                                      AED{" "}
                                      <span className="fw-bold">
                                        {selectCartApi?.data?.total_price}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-xxl-4 col-lg-5">
                          <div className="row">
                            <div className="address-gray card edr-card-radius">
                              <div className="card-body">
                                <div className="row mx-3">
                                  <div className="col-md-6">
                                    <h6 className="fw-bold float-start">
                                      Delivery Address
                                    </h6>
                                  </div>
                                  <div className="col-md-6">
                                    <span
                                      className="edr-anchor float-end"
                                      onClick={() => {
                                        navigate(`/primaryaddresslist`);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      change
                                    </span>
                                  </div>
                                </div>
                                <div className="card edr-card-radius mt-3 mx-3">
                                  {"address" in address &&
                                  address?.address.length !== 0 ? (
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-1">
                                          <img alt="" src={place} />
                                        </div>
                                        <div className="col-md-11 addressName">
                                          {address?.address[0]?.house +
                                            ", " +
                                            address?.address[0]?.area +
                                            ", "}
                                          <br />
                                          {address?.address[0]?.city +
                                            ", " +
                                            address?.address[0]?.locality}
                                          <br />
                                          {address?.address[0]?.landmark +
                                            ", " +
                                            address?.address[0]?.country}
                                          <br />
                                          {address?.address[0]?.pincode}
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="row">
                                        <div className="col-md-1">
                                          <img alt="" src={phone} />
                                        </div>
                                        <div className="col-md-11 addressName">
                                          {address?.address[0]?.mobile}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-11 addressName text-center mt-3">
                                          <p>
                                            <b>No Address Found</b>
                                          </p>
                                          <button
                                            type="button"
                                            className="btn secondary-bg-color text-white"
                                            onClick={gotoAddAddress}
                                          >
                                            {"Add Address"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="row mx-3 mt-5">
                                  <div className="col-md-6">
                                    <h6 className="fw-bold float-start">
                                      Payment method
                                    </h6>
                                  </div>
                                  <div className="col-md-6">
                                    {/* <span className="edr-anchor fw-bold float-end">
                                        change
                                      </span> */}
                                  </div>
                                </div>
                                <div className="card edr-card-radius mt-3 mx-3">
                                  <div className="card-body">
                                    <div className="row px-3">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault1"
                                          value="Online-Cod"
                                          onChange={(e) =>
                                            setTransactionType(e.target.value)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault1"
                                        >
                                          Cash
                                        </label>
                                        <img
                                          className="mx-2"
                                          src={cash}
                                          alt="Cash payment available"
                                          width="35"
                                          height="20"
                                        ></img>
                                      </div>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault"
                                          id="flexRadioDefault2"
                                          onChange={(e) =>
                                            setTransactionType(e.target.value)
                                          }
                                          value="Online-Ngenius"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexRadioDefault2"
                                        >
                                          Credit Card
                                        </label>
                                        <img
                                          className="mx-2"
                                          src={visa}
                                          alt="Visa payment available"
                                          width="35"
                                          height="20"
                                        ></img>
                                        <img
                                          className="mx-2"
                                          src={mastercard}
                                          alt="Mastercard payment available"
                                          width="33"
                                          height="20"
                                        ></img>
                                      </div>
                                    </div>

                                    {transactionTypeError === true ? (
                                      <div className="text-danger p-3">
                                        *Please Select a transaction type
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="row px-4 py-4 center">
                                  {selectCartApi?.data?.isDeliverable ===
                                  true ? (
                                    <button
                                      disabled={isPlaceOrder}
                                      className={`btn btn-md secondary-bg-color text-white btn-explore fw-bold center`}
                                      onClick={() => {
                                        if (selectCartApi?.data?.cart_items) {
                                          postPlaceOrder();
                                        } else {
                                          toasterService(
                                            toasterType.danger,
                                            "Your cart is empty"
                                          );
                                        }
                                      }}
                                    >
                                      PLACE ORDER
                                    </button>
                                  ) : (
                                    <button
                                      disabled={
                                        selectCartApi?.data?.isDeliverable
                                          ? false
                                          : true
                                      }
                                      className={`btn btn-md btn-danger text-white fw-bold center`}
                                    >
                                      ITEM IS NOT DELIVERABLE
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card edr-card-radius p-5"
                        style={{ border: "5px solid rgba(0,0,0,.125)" }}
                      >
                        <div className="row">
                          <div className="col-12 text-center my-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="50"
                              height="50"
                              fill="red"
                              className="bi bi-cart text-white"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                          </div>
                          <div className="col-12 text-center my-2">
                            <h4>
                              Yummy dishes are around and still your cart is
                              empty!
                              <br />
                              Head over to any restaurant to order delicious
                              dishes.
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
