import { useEffect } from "react";

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container-fluid bg-white mt-5 pt-5 page-min-height">
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-fluid mt-5 mb-5">
              <h2 className="TermsHeder">TERMS AND CONDITIONS</h2>
              <h4 className="headingEdit">Agreement To Terms </h4>
              <p className="fontEditTerms">
                These Terms of Use constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                (“you”) and Eat and Drink Group ("Company", “we”, “us”, or
                “our”), concerning your access to and use of the
                https://www.eatanddrink.ae website as well as any other media
                form, media channel, mobile website or mobile application
                related, linked, or otherwise connected thereto (collectively,
                the “Site”). We are registered in the United Arab Emirates and
                have our registered office at Dubai 65892. The Site provides an
                online marketplace for the following goods, products, and/or
                services: Order food online (the “Marketplace Offerings”). In
                order to help make the Site a secure environment for the
                purchase and sale of Marketplace Offerings, all users are
                required to accept and comply with these Terms of Use. You agree
                that by accessing the Site and/or the Marketplace Offerings, you
                have read, understood, and agree to be bound by all of these
                Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF
                USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE
                AND/OR THE MARKETPLACE OFFERINGS AND YOU MUST DISCONTINUE USE
                IMMEDIATELY.{" "}
              </p>
              <p className="fontEditTerms">
                Supplemental terms and conditions or documents that may be
                posted on the Site from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Terms
                of Use at any time and for any reason. We will alert you about
                any changes by updating the “Last updated” date of these Terms
                of Use, and you waive any right to receive specific notice of
                each such change. Please ensure that you check the applicable
                Terms every time you use our Site so that you understand which
                Terms apply. You will be subject to, and will be deemed to have
                been made aware of and to have accepted, the changes in any
                revised Terms of Use by your continued use of the Site after the
                date such revised Terms of Use are posted.{" "}
              </p>
              <p className="fontEditTerms">
                The information provided on the Site is not intended for
                distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Site from
                other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws are applicable.{" "}
              </p>
              <p className="fontEditTerms">
                The Site is intended for users who are at least 7 years old.
                Persons under the age of 7 are not permitted to use or register
                for the Site or use the Marketplace Offerings.{" "}
              </p>
              <h4 className="headingEdit">INTELLECTUAL PROPERTY RIGHTS </h4>
              <p className="fontEditTerms">
                Unless otherwise indicated, the Site and the Marketplace
                Offerings are our proprietary property and all source code,
                databases, functionality, software, website designs, audio,
                video, text, photographs, and graphics on the Site
                (collectively, the “Content”) and the trademarks, service marks,
                and logos contained therein (the “Marks”) are owned or
                controlled by us or licensed to us, and are protected by
                copyright and trademark laws and various other intellectual
                property rights and unfair competition laws of the United
                States, international copyright laws, and international
                conventions. The Content and the Marks are provided on the Site
                “AS IS” for your information and personal use only. Except as
                expressly provided in these Terms of Use, no part of the Site or
                the Marketplace Offerings and no Content or Marks maybe copied,
                reproduced, aggregated, republished, uploaded, posted, publicly
                displayed, encoded, translated, transmitted, distributed, sold,
                licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.
                Provided that you are eligible to use the Site, you are granted
                a limited license to access and use the Site and to download or
                print a copy of any portion of the Content to which you have
                properly gained access solely for your personal, non-commercial
                use. We reserve all rights not expressly granted to you in and
                to the Site, the Content and the Marks.
              </p>
              <h4 className="headingEdit">USER REPRESENTATIONS</h4>
              <p className="fontEditTerms">
                By using the Site or the Marketplace Offerings, you represent
                and warrant that: (1) all registration information you submit
                will be true, accurate, current, and complete; (2) you will
                maintain the accuracy of such information and promptly update
                such registration information as necessary; (3) you have the
                legal capacity and you agree to comply with these Terms of Use;
                (4) you are not a minor in the jurisdiction in which you reside
                (5) you will not access the Site or the Marketplace Offerings
                through automated or non-human means, whether through a bot,
                script or otherwise; (6) you will not use the Site for any
                illegal or unauthorized purpose; and (7) your use of the Site or
                the Marketplace Offerings will not violate any applicable law or
                regulation. If you provide any information that is untrue,
                inaccurate, not current, or incomplete, we have the right to
                suspend or terminate your account and refuse any and all current
                or future use of the Site (or any portion thereof). You may not
                use the Site or the Marketplace Offerings for any illegal or
                unauthorized purpose nor may you, in the use of Marketplace
                Offerings, violate any laws. Among unauthorized Marketplace
                Offerings are the following: intoxicants of any sort; illegal
                drugs or other illegal products; alcoholic beverages; games of
                chance; and pornography or graphic adult content, images, or
                other adult products. Postings of any unauthorized products or
                content may result in immediate termination of your account and
                a lifetime ban from use of the Site. We are a service provider
                and make no representations as to the safety, effectiveness,
                adequacy, accuracy, availability, prices, ratings, reviews, or
                legality of any of the information contained on the Site or the
                Marketplace Offerings displayed or offered through the Site. You
                understand and agree that the content of the Site does not
                contain or constitute representations to be reasonably relied
                upon, and you agree to hold us harmless from any errors,
                omissions, or misrepresentations contained within the Site’s
                content. We do not endorse or recommend any Marketplace
                Offerings and the Site is provided for informational and
                advertising purposes only.
              </p>
              <h4 className="headingEdit">USER REGISTRATION</h4>
              <p className="fontEditTerms">
                You may be required to register with the Site in order to access
                the Marketplace Offerings. You agree to keep your password
                confidential and will be responsible for all use of your account
                and password. We reserve the right to remove, reclaim, or change
                a username you select if we determine, in our sole discretion,
                that such username is inappropriate, obscene, or otherwise
                objectionable
              </p>
              <h4 className="headingEdit">MARKETPLACE OFFERINGS</h4>
              <p className="fontEditTerms">
                We reserve the right to limit the quantities of the Marketplace
                Offerings offered or available on the Site. All descriptions or
                pricing of the Marketplace Offerings are subject to change at
                any time without notice, at our sole discretion. We reserve the
                right to discontinue any Marketplace Offerings at any time for
                any reason. We do not warrant that the quality of any of the
                Marketplace Offerings purchased by you will meet your
                expectations or that any errors in the Site will be corrected.
                In order for us to maintain high standards of quality and
                hygiene, there are periods of equipment maintenance during which
                certain food items will not be available. Breakfast is currently
                delivered only in limited areas. Breakfast Orders are taken
                until Morning Session. Cash payment shall be made at point of
                delivery of food.
              </p>
              <h4 className="headingEdit">PURCHASES AND PAYMENT</h4>
              <p className="fontEditTerms">
                We accept the following forms of payment:{" "}
              </p>
              <ul>
                <li className="fontEditTerms">MasterCard</li>
                <li className="fontEditTerms">Visa</li>
                <li className="fontEditTerms">American Express</li>
              </ul>
              <p className="fontEditTerms">
                You agree to provide current, complete, and accurate purchase
                and account information for all purchases of the Marketplace
                Offerings made via the Site. You further agree to promptly
                update account and payment information, including email address,
                payment method, and payment card expiration date, so that we can
                complete your transactions and contact you as needed. Sales tax
                will be added to the price of purchases as deemed required by
                us. We may change prices at any time. All payments shall be in
                AED. You agree to pay all charges at the prices then in effect
                for your purchases and any applicable shipping fees, and you
                authorize us to charge your chosen payment provider for any such
                amounts upon placing your order. We reserve the right to correct
                any errors or mistakes in pricing, even if we have already
                requested or received payment. We reserve the right to refuse
                any order placed through the Site. We may, in our sole
                discretion, limit or cancel quantities purchased per person, per
                household, or per order. These restrictions may include orders
                placed by or under the same customer account, the same payment
                method, and/or orders that use the same billing or shipping
                address. We reserve the right to limit or prohibit orders that,
                in our sole judgment, appear to be placed by dealers, resellers,
                or distributors.
              </p>
              <h4 className="headingEdit">REFUNDS POLICY</h4>
              <h5 className="headingEdit">Returns</h5>
              <p className="fontEditTerms">
                You have the ability to update or abandon your order at any time
                until you confirm and pay for your order. However, once you
                confirm and pay for your order you cannot cancel it. If you
                desire to seek a refund, including if the products are
                unsatisfactory or for any other reason, please contact the
                restaurant where you purchased the product regarding any refund
                due to you. Your legal rights in this respect are not affected
                by anything in these terms.
              </p>
              <h4 className="headingEdit">PROHIBITED ACTIVITIES</h4>
              <p className="fontEditTerms">
                You may not access or use the Site for any purpose other than
                that for which we make the Site available. The Site may not be
                used in connection with any commercial end eaves except those
                that are specifically endorsed or approved buys. As a user of
                the Site, you agree not to:
              </p>
              <ul>
                <li className="fontEditTerms">
                  1. Systematically retrieve data or other content from the Site
                  to create or compile, directly or indirectly, a collection,
                  compilation, database, or directory without written permission
                  from us.
                </li>
                <li className="fontEditTerms">
                  2. Trick, defraud, or mislead us and other users, especially
                  in any attempt to learn sensitive account information such as
                  user passwords.
                </li>
                <li className="fontEditTerms">
                  3. Circumvent, disable, or otherwise interfere with
                  security-related features of the Site, including features that
                  prevent or restrict the use or copying of any Content or
                  enforce limitations on the use of the Site and/or the Content
                  contained therein.
                </li>
                <li className="fontEditTerms">
                  4. Disparage, tarnish, or otherwise harm, in our opinion, us
                  and/or the Site.
                </li>
                <li className="fontEditTerms">
                  5. Use any information obtained from the Site in order to
                  harass, abuse, or harm another person.
                </li>
                <li className="fontEditTerms">
                  6. Make improper use of our support services or submit false
                  reports of abuse or misconduct.
                </li>
                <li className="fontEditTerms">
                  7. Use the Site in a manner inconsistent with any applicable
                  laws or regulations.
                </li>
                <li className="fontEditTerms">
                  8. Engage in unauthorized framing of or linking to the Site.
                </li>
                <li className="fontEditTerms">
                  9. Upload or transmit (or attempt to upload or to transmit)
                  viruses, Trojan horses, or other material, including excessive
                  use of capital letters and spamming (continuous posting of
                  repetitive text), that interferes with any party’s
                  uninterrupted use and enjoyment of the Site or modifies,
                  impairs, disrupts, alters, or interferes with the use,
                  features, functions, operation, or maintenance of the
                  Marketplace Offerings.
                </li>
                <li className="fontEditTerms">
                  10. Engage in any automated use of the system, such as using
                  scripts to send comments or messages, or using any data
                  mining, robots, or similar data gathering and extraction
                  tools.
                </li>
                <li className="fontEditTerms">
                  11. Delete the copyright or other proprietary rights notice
                  from any Content.
                </li>
                <li className="fontEditTerms">
                  12. Attempt to impersonate another user or person or use the
                  username of another user.
                </li>
                <li className="fontEditTerms">
                  13. Upload or transmit (or attempt to upload or to transmit)
                  any material that acts as a passive or active information
                  collection or transmission mechanism, including without
                  limitation, clear graphics interchange formats “gifs”, 1×1
                  pixels, web bugs, cookies, or other similar devices (sometimes
                  referred to as “spyware” or “passive collection mechanisms” or
                  “pcms”).
                </li>
                <li className="fontEditTerms">
                  14. Interfere with, disrupt, or create an undue burden on the
                  Site or the networks or services connected to the Site.
                </li>
                <li className="fontEditTerms">
                  15. Harass, annoy, intimidate, or threaten any of our
                  employees or agents engaged in providing any portion of the
                  Marketplace Offerings to you.
                </li>
                <li className="fontEditTerms">
                  16. Attempt to bypass any measures of the Site designed to
                  prevent or restrict access to the Site, or any portion of the
                  Site.
                </li>
                <li className="fontEditTerms">
                  17. Copy or adapt the Site’s software, including but not
                  limited to Flash, PHP, HTML, JavaScript, or other code.
                </li>
                <li className="fontEditTerms">
                  18. Except as permitted by applicable law, decipher,
                  decompile, disassemble, or reverse engineer any of the
                  software comprising or in any way making up a part of the
                  Site.
                </li>
                <li className="fontEditTerms">
                  19. Except as may be the result of standard search engine or
                  Internet browser usage, use, launch, develop, or distribute
                  any automated system, including without limitation, any
                  spider, robot, cheat utility, scraper, or offline reader that
                  accesses the Site, or using or launching any unauthorized
                  script or other software.
                </li>
                <li className="fontEditTerms">
                  20. Use a buying agent or purchasing agent to make purchases
                  on the Site.
                </li>
                <li className="fontEditTerms">
                  21. Make any unauthorized use of the Marketplace Offerings,
                  including collecting usernames and/or email addresses of users
                  by electronic or other means for the purpose of sending
                  unsolicited email, or creating user accounts by automated
                  means or under false pretences.
                </li>
                <li className="fontEditTerms">
                  22. Use the Marketplace Offerings as part of any effort to
                  compete with us or otherwise use the Site and/or the Content
                  for any revenue-generating endeavour or commercial enterprise.
                </li>
                <li className="fontEditTerms">
                  23. Use the Site to advertise or offer to sell goods and
                  services.
                </li>
                <li className="fontEditTerms">
                  24. Sell or otherwise transfer your profile.
                </li>
              </ul>
              <h4 className="headingEdit">CONTRIBUTION LICENSE</h4>
              <p className="fontEditTerms">
                You and the Site agree that we may access, store, process, and
                use any information and personal data that you provide following
                the terms of the Privacy Policy and your choices (including
                settings). By submitting suggestions or other feedback regarding
                the Site, you agree that we can use and share such feedback for
                any purpose without compensation to you. We do not assert any
                ownership over your Contributions. You retain full ownership of
                all of your Contributions and any intellectual property rights
                or other proprietary rights associated with your Contributions.
                We are not liable for any statements or representations in your
                Contributions provided by you in any area on the Site. You are
                solely responsible for your Contributions to the Site and you
                expressly agree to exonerate us from any and all responsibility
                and to refrain from any legal action against us regarding your
                Contributions.
              </p>
              <h4 className="headingEdit">MOBILE APPLICATION LICENSE</h4>
              <p className="fontEditTerms">
                If you access the Marketplace Offerings via a mobile
                application, then we grant you are evocable, non-exclusive,
                on-transferable, limited right to install and use the mobile
                application on wireless electronic devices owned or controlled
                by you, and to access and use the mobile application on such
                devices strictly in accordance with the terms and conditions of
                this mobile application license contained in these Terms of Use.
                You shall not: (1) except as permitted by applicable law,
                reverse engineer, disassemble, attempt to derive the source code
                of, or decrypt the application; (2)make any modification,
                adaptation, improvement, enhancement, translation, or derivative
                work from the application; (3) violate any applicable laws,
                rules, or regulations in connection with your access or use of
                the application; (4) remove, alter, or obscure any proprietary
                notice (including any notice of copyright or trademark)posted by
                us or the licensors of the application; (5) use the application
                for any revenue generating endeavour, commercial enterprise, or
                other purpose for which it is not designed or intended; (6) make
                the application available over a network or other environment
                permitting access or use by multiple devices or users at the
                same time;(7) use the application for creating a product,
                service, or software that is, directly or indirectly,
                competitive with or in any way a substitute for the application;
                (8) use the application to send automated queries to any website
                or to send any unsolicited commercial e-mail; or (9) use any
                proprietary information or any of our interfaces or our other
                intellectual property in the design, development, manufacture,
                licensing, or distribution of any applications, accessories, or
                devices for use with the application
              </p>
              <h4 className="headingEdit">Apple and Android Devices</h4>
              <p className="fontEditTerms">
                The following terms apply when you use a mobile application
                obtained from either the Apple Store or Google Play (each an
                “App Distributor”) to access the Marketplace Offerings: (1) the
                license granted to you for our mobile application is limited to
                anon-transferable license to use the application on a device
                that utilizes the Apple iOS or Android operating systems, as
                applicable, and in accordance with the usage rules set forth in
                the applicable App Distributor’s terms of service;(2) we are
                responsible for providing any maintenance and support services
                with respect to the mobile application as specified in the terms
                and conditions of this mobile application license contained in
                these Terms of Use or as otherwise required under applicable
                law, and you acknowledge that each App Distributor has no
                obligation whatsoever to furnish any maintenance and support
                services with respect to the mobile application; (3) in the
                event of any failure of the mobile application to conform to any
                applicable warranty, you may notify the applicable App
                Distributor, and the App Distributor, in accordance with its
                terms and policies, may refund the purchase price, if any, paid
                for the mobile application, and to the maximum extent permitted
                by applicable law, the App Distributor will have no other
                warranty obligation whatsoever with respect to the mobile
                application; (4) you represent and warrant that (i) you are not
                located in a country that is subject to a U.S. government
                embargo, or that has been designated by the U.S. government as a
                “terrorist supporting” country and(ii) you are not listed on any
                U.S. government list of prohibited or restricted parties; (5)
                you must comply with applicable third-party terms of agreement
                when using the mobile application, e.g., if you have a VoIP
                application, then you must not be in violation of their wireless
                data service agreement when using the mobile application; and
                (6) you acknowledge and agree that the App Distributors are
                third-party beneficiaries of the terms and conditions in this
                mobile application license contained in these Terms of Use, and
                that each App Distributor will have the right (and will be
                deemed to have accepted the right)to enforce the terms and
                conditions in this mobile application license contained in these
                Terms of Use against you as a third-party beneficiary thereof.
              </p>
              <h4 className="headingEdit">SUBMISSIONS</h4>
              <p className="fontEditTerms">
                You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback, or other information regarding the
                Site or the Marketplace Offerings("Submissions") provided by you
                to us are non-confidential and shall become our sole property.
                We shall own exclusive rights, including all intellectual
                property rights, and shall be entitled to the unrestricted use
                and dissemination of these Sub missions for any lawful purpose,
                commercial or otherwise, without acknowledgment or compensation
                to you. You hereby waive all moral rights to any such
                Submissions, and you hereby warrant that any such Submissions
                are original with you or that you have the right to submit such
                Submissions. You agree there shall be no recourse against us for
                any alleged or actual infringement or misappropriation of any
                proprietary right in your Submissions.{" "}
              </p>
              <h4 className="headingEdit">SITE MANAGEMENT</h4>
              <p className="fontEditTerms">
                We reserve the right, but not the obligation, to: (1) monitor
                the Site for violations of these Terms of Use; (2) take
                appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Terms of Use, including
                without limitation, reporting such user to law enforcement
                authorities; (3) in our sole discretion and without limitation,
                refuse, restrict access to, limit the availability of, or
                disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion
                and without limitation, notice, or liability, to remove from the
                Site or otherwise disable all files and content that are
                excessive in size or are in any way burdensome to our systems;
                and (5) otherwise manage the Site in a manner designed to
                protect our rights and property and to facilitate the proper
                functioning of the Site and the Marketplace Offerings.
              </p>
              <h4 className="headingEdit">PRIVACY POLICY</h4>
              <p className="fontEditTerms">
                We care about data privacy and security. By using the Site or
                the Marketplace Offerings, you agree to be bound by our Privacy
                Policy posted on the Site, which is incorporated into these
                Terms of Use. Please be advised the Site and the Marketplace
                Offerings are hosted in the United Arab Emirates. If you access
                the Site or the Marketplace Offerings from any other region of
                the world with laws or other requirements governing personal
                data collection, use, or disclosure that differ from applicable
                laws in the United Arab Emirates, then through your continued
                use of the Site, you are transferring your data to the United
                Arab Emirates, and you agree to have your data transferred to
                and processed in the United Arab Emirates.
              </p>
              <h4 className="headingEdit">TERM ANDTERMINATION</h4>
              <p className="fontEditTerms">
                These Terms of Use shall remain in full force and effect while
                you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
                TERMS OF USE, WERESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
                WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
                AND THEMARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN
                IPADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
                APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
                PARTICIPATION IN THE SITE AND THE MARKETPLACE OFFERINGS OR
                DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT
                YOUPOSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                If we terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party.
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.
              </p>
              <h4 className="headingEdit">MODIFICATIONS AND INTERRUPTIONS</h4>
              <p className="fontEditTerms">
                We reserve the right to change, modify, or remove the contents
                of the Site at any time or for any reason at our sole discretion
                without notice. However, we have no obligation to update any
                information on our Site. We also reserve the right to modify or
                discontinue all or part of the Marketplace Offerings without
                notice at any time. Weill not be liable to you or any third
                party for any modification, price change, suspension, or
                discontinuance of the Site or the Marketplace Offerings. We
                cannot guarantee the Site and the Marketplace Offerings will be
                available at all times. We may experience hardware, software, or
                other problems or need to perform maintenance related to the
                Site, resulting in interruptions, delays, or errors. Were serve
                the right to change, revise, update, suspend, discontinue, or
                otherwise modify the Site or the Marketplace Offerings at any
                time or for any reason without notice to you. You agree that we
                have no liability whatsoever for any loss, damage, or
                inconvenience caused by your inability to access or use the Site
                or the Marketplace Offerings during any downtime or
                discontinuance of the Site or the Marketplace Offerings. Nothing
                in these Terms of Use will be construed to obligate us to
                maintain and support the Site or the Marketplace Offerings or to
                supply any corrections, updates, or releases in connection
                therewith.
              </p>
              <h4 className="headingEdit">GOVERNING LAW</h4>
              <p className="fontEditTerms">
                These terms shall be governed by and defined following the laws
                of the United Arab Emirates. Eat and Drink Group and yourself
                irrevocably consent that the courts of the United Arab Emirates
                shall have exclusive jurisdiction to resolve any dispute which
                may arise in connection with these terms.{" "}
              </p>
              <h4 className="headingEdit">CORRECTIONS</h4>
              <p className="fontEditTerms">
                There may be information on the Site that contains typographical
                errors, inaccuracies, or omissions that may relate to the
                Marketplace Offerings, including descriptions, pricing,
                availability, and various other information. We reserve the
                right to correct any errors, inaccuracies, or omissions and to
                change or update the information on the Site at any time,
                without prior notice.{" "}
              </p>
              <h4 className="headingEdit">DISCLAIMER</h4>
              <p className="fontEditTerms">
                {" "}
                THE SITE AND THE MARKET PLACE OFFERINGS ARE PROVIDED ON AN AS-IS
                AND AS-AVAILABLE BASIS. YOUAGREE THAT YOUR USE OF THE SITE AND
                OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT
                PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                IMPLIED, IN CONNECTION WITH THE SITE AND THE MARKET PLACE
                OFFERINGS AND YOUR USE THERE OF, INCLUDING, WITHOUT LIMITATION,
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES
                OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE
                AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                (1)ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
                (2)PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHAT SO
                EVER,RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY
                AUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
                ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
                FROM THE SITE OR THE MARKETPLACE OFFERINGS,(5) ANY BUGS,
                VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO
                OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)ANY ERRORS OR
                OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
                OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                POSTED, TRANSMITTED,
              </p>
              <h4 className="headingEdit">LIMITATIONS OF LIABILITY</h4>
              <p className="fontEditTerms">
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                BELIEVABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                PUNITIVEDAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF
                DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE OR THE
                MARKETPLACE OFFERINGS, EVEN IF WE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. NOT WITHSTANDING ANYTHING TO
                THECONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                CAUSEWHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL
                AT ALL TIMES BE LIMITED TO THEAMOUNT PAID, IF ANY, BY YOU TO US.
                CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
                THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                YOU MAY HAVE ADDITIONAL RIGHTS.
              </p>
              <h4 className="headingEdit">INDEMNIFICATION</h4>
              <p className="fontEditTerms">
                You agree to defend, indemnify, and hold us harmless, including
                our subsidiaries, affiliates, and all of our respective
                officers, agents, partners, and employees, from and against any
                loss, damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by any third party due to or
                arising out of: (1) use of the Marketplace Offerings; (2) breach
                of these Terms of Use; (3) any breach of your representations
                and warranties set forth in these Terms of Use; (4) your
                violation of the rights of a third party, including but not
                limited to intellectual property rights; or (5) any overt
                harmful act toward any other user of the Site or the Marketplace
                Offerings with whom you connected via the Site. Notwithstanding
                the foregoing, were serve the right, at your expense, to assume
                the exclusive defence and control of any matter for which you
                are required to indemnify us, and you agree to cooperate, at
                your expense, with our defence of such claims. We will use
                reasonable efforts to notify you of any such claim, action, or
                proceeding which is subject to this indemnification upon
                becoming aware of it.
              </p>
              <h4 className="headingEdit">USER DATA</h4>
              <p className="fontEditTerms">
                We will maintain certain data that you transmit to the Site for
                the purpose of managing the performance of the Marketplace
                Offerings, as well as data relating to your use of the
                Marketplace Offerings. Although we perform regular routine
                backups of data, you are solely responsible for all data that
                you transmit or that relates to any activity you have undertaken
                using the Marketplace Offerings. You agree that we shall have no
                liability to you for any loss or corruption of any such data,
                and you hereby waive any right of action against us arising from
                any such loss or corruption of such data.{" "}
              </p>
              <h4 className="headingEdit">
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, ANDSIGNATURES
              </h4>
              <p className="fontEditTerms">
                Visiting the Site, sending us emails, and completing online
                forms constitute electronic communications. You consent to
                receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications we
                provide to you electronically, via email and on the Site,
                satisfy any legal requirement that such communication be in
                writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY
                OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or
                requirements under any statutes, regulations, rules, ordinances,
                or other laws in any jurisdiction which require an original
                signature or delivery or retention of non-electronic records, or
                to payments or the granting of credits by any means other than
                electronic means.{" "}
              </p>
              <h4 className="headingEdit">MISCELLANEOUS</h4>
              <p className="fontEditTerms">
                These Terms of Use and any policies or operating rules posted by
                us on the Site or in respect to the Marketplace Offerings
                constitute the entire agreement and understanding between you
                and us. Our failure to exercise or enforce any right or
                provision of these Terms of Use shall not operate as a waiver of
                such right or provision. These Terms of Use operate to the
                fullest extent permissible by law. We may assign any or all of
                our rights and obligations to others at any time. We shall not
                be responsible or liable for any loss, damage, delay, or failure
                to act caused by any cause beyond our reasonable control. If any
                provision or part of a provision of these Terms of Use is
                determined to be unlawful, void, or unenforceable, that
                provision or part of the provision is deemed severable from
                these Terms of Use and does not affect the validity and
                enforceability of any remaining provisions. There is no joint
                venture, partnership, employment or agency relationship created
                between you and us as a result of these Terms of Use or use of
                the Marketplace Offerings. You agree that these Terms of Use
                will not be construed against us by virtue of having drafted
                them. You hereby waive any and all defences you may have based
                on the electronic form of these Terms of Use and the lack of
                signing by the parties hereto to execute these Terms of Use.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
