import React, { useEffect, useState } from "react";
import moment from "moment";
import { BsShop } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { GrRadialSelected } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { postRating, selectRating } from "../features/rating/ratingSlice";
import { getOrderDetail, selectOrder } from "../features/order/orderSlice";
import { toasterService, toasterType } from "../services/toasterService";

const status = {
  New: "Waiting for your Order to get Accepted",
  Accepted: "Your Order is Accepted",
  Rejected: "Your Order is Rejected",
  Prepared: "Preparing your Order",
  Assigned: "Your Order is Picked",
  OutForDelivery: "Your Order is Out For Delivery",
  Delivered: "Your Order is Delivered",
};

const OrderDetailComponent = (props) => {
  const navigate = useNavigate();
  const [ratingButton, setRatingButton] = useState(false);
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const orderData = useSelector(selectOrder);
  const orderDetailValue = orderData?.resultOrderDetail;
  const postRatingData = useSelector(selectRating);
  const bc = new BroadcastChannel("vaas_notificationsForEDRUser");
  const ratingChanged = (newRating) => {
    if (orderDetailValue?.data[0]?.order_rating === undefined) {
      setRatingButton(true);
      setRating(newRating);
    } else {
      toasterService(toasterType.danger, "Rating Already Added");
    }
  };

  const ratingSubmit = async () => {
    await dispatch(postRating({ orderId: props?.order_id, rating: rating }));
    await setRatingButton(false);
  };

  useEffect(() => {
    bc.onmessage = (messageEvent) => {
      dispatch(getOrderDetail({ orderId: props.order_id }));
    };
  }, [bc.onmessage]);

  useEffect(() => {
    dispatch(getOrderDetail({ orderId: props.order_id }));
  }, [dispatch, props.order_id]);

  useEffect(() => {
    if (postRatingData.status === "complete") {
      dispatch(getOrderDetail({ orderId: props.order_id }));
    }
  }, [dispatch, postRatingData.status, props.order_id]);

  return (
    <div className="container p-5">
      {orderDetailValue?.data[0] !== undefined ? (
        <div className="row">
          <div className="col-12">
            <div className="row">
              <h5>{"ORDER# " + orderDetailValue?.data[0]?.order_id}</h5>
            </div>
            <div className="row">
              <div className="col">
                Order created on{" "}
                {moment(orderDetailValue?.data[0]?.createdAt).format("llll")}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1">
                <BsShop />
              </div>
              <div className="col">
                {`${orderDetailValue?.data[0]?.shop_id?.address?.house}, ${orderDetailValue?.data[0]?.shop_id?.address?.area}, ${orderDetailValue?.data[0]?.shop_id?.address?.locality}, ${orderDetailValue?.data[0]?.shop_id?.address?.city}`}
              </div>
            </div>
            <div className="verticalLine py-3 mt-1"></div>
            <div className="row">
              <div className="col-1">
                <ImLocation />
              </div>
              <div className="col">
                {`${orderDetailValue?.data[0]?.location?.house}, ${orderDetailValue?.data[0]?.location?.area}, ${orderDetailValue?.data[0]?.location?.locality}, ${orderDetailValue?.data[0]?.location?.city}, ${orderDetailValue?.data[0]?.location?.landmark}, ${orderDetailValue?.data[0]?.location?.country}`}
              </div>
            </div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col text-center">
                {status[orderDetailValue?.data[0]?.order_status]}
              </div>
            </div>
            {orderDetailValue?.data[0]?.order_status === "Delivered" ? (
              <>
                <div className="horizontalLine mt-3"></div>
                <div className="row pt-4">
                  <div className="col-4 pt-1">Rate your Order</div>
                  <div className="col-4">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={24}
                      activeColor="#ffd700"
                      value={
                        orderDetailValue?.data[0]?.order_rating
                          ? orderDetailValue?.data[0]?.order_rating
                          : 0
                      }
                      edit={
                        orderDetailValue?.data[0]?.order_rating ? false : true
                      }
                    />
                  </div>
                  <div className="col-4 pt-1">
                    {ratingButton === true ? (
                      <button
                        className="btn secondary-bg-color text-white"
                        onClick={() => {
                          ratingSubmit();
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="horizontalLine mt-3"></div>
            <div className="row mt-4">
              <div className="col-1"></div>
              <div className="col">
                {(orderDetailValue?.data[0]?.order_items).length === 1
                  ? "1 ITEM"
                  : (orderDetailValue?.data[0]?.order_items).length + " ITEMS"}
              </div>
            </div>
            {orderDetailValue?.data[0]?.order_items.map((detail, key) => {
              return (
                <div className="row" key={key}>
                  <div className="col-1">
                    <GrRadialSelected />
                  </div>
                  <div className="col-6">
                    {detail?.product_name} &#215; {detail?.quantity}
                    <br />
                    {detail?.comments}
                  </div>
                  <div className="col-4" style={{ marginLeft: "auto" }}>
                    {`AED ${detail?.final_price}`}
                  </div>
                </div>
              );
            })}
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col">
                <div className="horizontalLine2"></div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-6">Amount (EXCL. VAT):</div>
              <div className="col-4" style={{ marginLeft: "auto" }}>
                {`AED ${orderDetailValue?.data[0]?.original_price}`}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-6">VAT @ 5%:</div>
              <div className="col-4" style={{ marginLeft: "auto" }}>
                {`AED ${orderDetailValue?.data[0]?.vat}`}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-6">Delivery fees:</div>
              <div className="col-4" style={{ marginLeft: "auto" }}>
                {`AED ${orderDetailValue?.data[0]?.delivery_charge}` ?? 0}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1"></div>
              <div className="col-6">Total Amount:</div>
              <div className="col-4" style={{ marginLeft: "auto" }}>
                {`AED ${orderDetailValue?.data[0]?.total_amount}`}
              </div>
            </div>
            {orderDetailValue?.data[0]?.order_status !== "Delivered" &&
            orderDetailValue?.data[0]?.order_status !== "Rejected" ? (
              <div className="row justify-content-center mt-3">
                <button
                  className="btn secondary-bg-color text-white"
                  onClick={() => {
                    navigate(
                      `/tracking/${orderDetailValue?.data[0]?.order_id}`
                    );
                  }}
                >
                  Track Order
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderDetailComponent;
