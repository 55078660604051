import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import "./Style.css";
import Home from "./pages/Home";
import Restaurent from "./pages/Restaurant";
import Cart from "./pages/Cart";
import ProductSearch from "./pages/ProductSearch";
import Profile from "./pages/Profile";
import Tracking from "./pages/Tracking";
import ChangeAddress from "./pages/ChangeAddress";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import CategoryView from "./pages/CategoryView";
import PaymentSuccess from "./pages/PaymentSuccess";
import SeeAll from "./pages/SeeAll";
import { Route, Routes } from "react-router-dom";
import PaymentFailed from "./pages/PaymentFailed";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import OrderDetail from "./pages/OrderDetail";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrimaryAddressList from "./pages/PrimaryAddressList";
import AddAddress from "./pages/AddAddress";
import PrivateRoute from "./components/PrivateRoute";
import { getFBToken, onMessageListener } from "./firebaseVaas";
import DeliveryAppDownload from "./pages/DeliveryAppDownload";
import { toasterService, toasterType } from "./services/toasterService";
import {
  fbTokenString,
  getItem,
  getToken,
  JwtValid,
  setItem,
} from "./services/standard";
import { useDispatch } from "react-redux";
import { postFbToken } from "./features/restaurant/headerSlice";

const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const renderLoader = () => (
  <>
    <p>Loading</p>
  </>
);
function App() {
  const dispatch = useDispatch();
  const [isTokenFound, setTokenFound] = useState(false);
  const [fbToken, setFbToken] = useState("");
  getFBToken(setTokenFound, setFbToken);
  const token = getToken();
  const bc = new BroadcastChannel("vaas_notificationsForEDRUser");
  useEffect(() => {
    console.log(
      "🚀 ~ file: App.js ~ line 70 ~ App ~ isTokenFound",
      isTokenFound
    );
    setItem(fbTokenString, fbToken);
    //todo: to post fb token to backend.
  }, [fbToken]);

  useEffect(() => {
    if (token && token?.length > 0) {
      if (JwtValid(token)) {
        let fbTokenInStorage = getItem(fbTokenString);
        fbTokenInStorage = fbTokenInStorage?.trim();
        if (fbTokenInStorage && fbTokenInStorage.length > 0) {
          dispatch(postFbToken(fbTokenInStorage));
        } else {
          console.log("No token!", fbTokenInStorage);
        }
      }
    }
  }, [dispatch, token, fbToken]);

  onMessageListener()
    .then((payload) => {
      console.log("🚀 ~ file: App.js ~ line 75 ~ .then ~ payload", payload);
      // toasterService(
      //   toasterType.notify,
      //   payload.notification.body,
      //   payload.notification.title
      // );
    })
    .catch((err) => console.log("failed: ", err));
  // inside the jsx being returned:
  // console.log(bc.onmessage);
  useEffect(() => {
    bc.onmessage = (messageEvent) => {
      toasterService(
        toasterType.notify,
        messageEvent?.data?.notification?.body,
        "EDR Delivery"
      );
    };
  }, [bc.onmessage]);

  return (
    <>
      <ToastContainer />
      <Suspense fallback={renderLoader()}>
        <Header />
      </Suspense>
      <Routes>
        <Route path="/changeaddress/:id" element={<ChangeAddress />} />
        <Route path="/primaryaddresslist" element={<PrimaryAddressList />} />
        <Route path="/addaddress" element={<AddAddress />} />
        <Route path="/tracking/:id" element={<Tracking />} />
        <Route exact path="/profile" element={<PrivateRoute />}>
          <Route exact path="/profile" element={<Profile />} />
        </Route>
        <Route path="/order-detail/:id" element={<OrderDetail />} />
        <Route path="/Restaurent/:id" element={<Restaurent />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/restaurant-search/:search" element={<ProductSearch />} />
        <Route path="/restaurant-search" element={<ProductSearch />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/CategoryView/:id" element={<CategoryView />} />
        <Route path="/SeeAllRestaurent" element={<SeeAll />} />
        <Route path="/" element={<Home />} />
        <Route exact path="/paymentsuccess" element={<PrivateRoute />}>
          <Route exact path="/paymentsuccess" element={<PaymentSuccess />} />
        </Route>
        <Route exact path="/paymentfailed" element={<PrivateRoute />}>
          <Route exact path="/paymentfailed" element={<PaymentFailed />} />
        </Route>
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="delivery-app-download" element={<DeliveryAppDownload />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Suspense fallback={renderLoader()}>
        <Footer />
      </Suspense>
    </>
  );
}
export default App;
