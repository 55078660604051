import { Dialog, Pane, Spinner } from "evergreen-ui";
import * as $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FloatingCartComponent from "../components/FloatingCartComponent";
import Product from "../components/Product";
import { selectPrimaryAddress } from "../features/restaurant/addressSlice";
import { setIsPopupModal } from "../features/restaurant/cartSlice";
import {
  currentCatCounterValue,
  getCategoryDetail,
  getCategoryInfoDetail,
  selectCategoryView,
} from "../features/restaurant/CategoryViewSlice";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import {
  addCart,
  selectProduct,
  setIsClearCart,
} from "../features/restaurant/restaurantSlice";
import {
  getLat,
  getLong,
  getToken,
  notLoggedInErrorMessage,
  setPageUrl,
} from "../services/standard";
import { toasterService, toasterType } from "../services/toasterService";
window["jQuery"] = window["$"] = $;

const Restaurant = () => {
  const addressModalReference2 = useRef();
  const categoryViewData = useSelector(selectCategoryView);
  const categoryView = categoryViewData.result;
  const [isLoading, setLoading] = useState(false);
  const addCartData = useSelector(selectProduct);
  const addCartValue = addCartData.resultAddCart;
  const isClearCart = addCartData.isClearCart;
  const [isShown, setIsShown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const pageRange = 10;
  const count = useSelector(currentCatCounterValue);
  const token = getToken();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectPrimaryAddressApiData = useSelector(selectPrimaryAddress);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData?.result;
  const statePopUp = useSelector((state) => state?.cart?.popupState);
  useEffect(() => {
    $(document).ready(function () {
      if (statePopUp === true) {
        $(addressModalReference2.current).modal("show");
      }
      $("#categoryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  }, [statePopUp]);

  function goto() {
    $(addressModalReference2.current).modal("hide");
    navigate("/sign-in");
  }

  function cancel() {
    $(addressModalReference2.current).modal("hide");
    dispatch(setIsPopupModal(false));
  }

  useEffect(() => {
    const currentURL = window.location.href;
    const forPage = currentURL.split("/").slice(-2)[0];
    const forId = currentURL.split("/").slice(-1)[0];
    const pageSpit = currentURL.split("/").slice(-3)[0];
    if (pageSpit === "") {
      const urlCurrentPage = ``;
      setPageUrl(urlCurrentPage);
    } else {
      const urlCurrentPage = `${forPage}/${forId}`;
      setPageUrl(urlCurrentPage);
    }
    setLoading(true);
    if (token) {
      dispatch(
        getCategoryInfoDetail({ catId: params?.id, pageNo: currentPage })
      );
    } else {
      if (getLat() && getLong()) {
        dispatch(
          getCategoryDetail({
            catId: params?.id,
            pageNo: currentPage,
            lat: getLat(),
            long: getLong(),
          })
        );
      } else {
        navigator?.geolocation?.getCurrentPosition(
          showPosition,
          (positionError) => {
            if (positionError) {
              toasterService(
                toasterType.danger,
                positionError?.message +
                  ". Please manually allow location in your browser."
              );
            }
          },
          { enableHighAccuracy: true }
        );
      }
    }
  }, [
    dispatch,
    count,
    token,
    params.id,
    currentPage,
    selectPrimaryAddressApi?.data?.address,
  ]);

  function showPosition(position) {
    dispatch(
      getCategoryDetail({
        catId: params?.id,
        pageNo: currentPage,
        lat: position?.coords?.latitude,
        long: position?.coords?.longitude,
      })
    );
  }

  useEffect(() => {
    if (categoryViewData.status === "complete") {
      setLoading(false);
    }
  }, [categoryViewData.status]);

  useEffect(() => {
    if (isClearCart) {
      setIsShown(true);
    }
  }, [isClearCart]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function clearCart() {
    await dispatch(
      addCart({
        product_id: addCartValue?.payload?.product_id,
        quantity: 1,
        shop_id: addCartValue?.payload?.shop_id,
        is_clear: true,
      })
    );
    if (params.id) {
      await dispatch(
        getCategoryInfoDetail({ catId: params?.id, pageNo: currentPage })
      );
    }
    await dispatch(getCartCountASyncThunk());
  }

  function setCartStatus() {
    dispatch(setIsClearCart(false));
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (token) {
      dispatch(
        getCategoryInfoDetail({ catId: params?.id, pageNo: pageNumber })
      );
    } else {
      if (getLat() && getLong()) {
        dispatch(
          getCategoryDetail({
            catId: params?.id,
            pageNo: pageNumber,
            lat: getLat(),
            long: getLong(),
          })
        );
      } else {
        navigator?.geolocation?.getCurrentPosition(
          showPosition,
          (positionError) => {
            if (positionError) {
              toasterService(
                toasterType.danger,
                positionError?.message +
                  ". Please manually allow location in your browser."
              );
            }
          },
          { enableHighAccuracy: true }
        );
      }
    }
  };
  return (
    <div>
      <div
        className="modal fade"
        id="categoryModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={addressModalReference2}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body fs-3">{notLoggedInErrorMessage}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(close) => {
                  cancel();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn secondary-bg-color text-white"
                onClick={(close) => {
                  goto();
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white mt-5 pt-5">
          <div className="container">
            <div className="col-md-6">
              <h4 className="fw-bold ">
                {categoryView?.data[0] !== undefined
                  ? categoryView.data[0]?.categoryName?.name
                  : ""}
              </h4>
            </div>
          </div>

          <div className="container mt-3 pb-5">
            <div className="row">
              <h1>
                {" "}
                {categoryView?.data[0] !== undefined
                  ? categoryView?.data[0].categoryArray[0]?.categoryName?.name
                  : ""}
              </h1>
              {categoryView?.data[0] !== undefined &&
              categoryView?.status !== false
                ? categoryView?.data[0].categoryArray?.map((s, key) => {
                    return (
                      <Product
                        key={key}
                        productName={s?.generic_product?.name}
                        shopName={s?.shop_info?.name}
                        isOpen={s?.shop_info?.is_open}
                        price={s?.price}
                        offerPrice={s?.final_price}
                        productId={s?._id}
                        productImage={s?.generic_product?.product_image}
                        shopId={s?.shop_info?._id}
                        cartQuantity={s?.cart_quantity}
                        outOfStock={s?.out_of_stock}
                        discounted={s?.discount}
                        discount={s?.discount}
                        discountType={s?.discount_type}
                        catId={params.id}
                        catPageNo={currentPage}
                        isDeliverable={s?.isDeliverable}
                        distance={s?.distance}
                      />
                    );
                  })
                : ""}
            </div>

            <Pane>
              <Dialog
                isShown={isShown}
                title="Cart is not empty"
                confirmLabel="Clear Cart"
                onConfirm={(close) => {
                  close();
                  clearCart(true);
                }}
                onCloseComplete={() => {
                  setIsShown(false);
                  setCartStatus();
                }}
              >
                <span className="fs-6">{addCartValue?.message}</span>
              </Dialog>
            </Pane>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center flex-row">
              <Pagination
                itemClass="page-item" // add it for bootstrap 4
                linkClass="page-link" // add it for bootstrap 4
                activePage={currentPage}
                itemsCountPerPage={recordPerPage}
                totalItemsCount={
                  categoryView.data.length !== 0
                    ? categoryView.data[0]?.totalCount?.total_rows
                    : 0
                }
                pageRangeDisplayed={pageRange}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
      <FloatingCartComponent />
    </div>
  );
};
export default Restaurant;
