import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAddress,
  selectAllAddress,
  currentCounterValue,
  getPrimaryAddress,
} from "../features/restaurant/addressSlice";
import { useNavigate } from "react-router-dom";
import { Pane, Spinner } from "evergreen-ui";
import AddressCard from "../components/AddressCard";

const PrimaryAddressList = () => {
  const [isLoading,setIsLoading] = useState(false);
  const selectAllAddressApiData = useSelector(selectAllAddress);
  const selectAllAddressApi = selectAllAddressApiData?.resultb;
  const allAddress = selectAllAddressApi?.data;
  const count = useSelector(currentCounterValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(async() => {
    setIsLoading(true);
    await dispatch(getAllAddress());
    await dispatch(getPrimaryAddress());
  }, [dispatch, count]);

  useEffect(() => {
    if (
      selectAllAddressApiData.status === "complete"
    ) {
      setIsLoading(false);
    }
  }, [selectAllAddressApiData.status]);

  
  const Add = () => {
    navigate("/addaddress");
  };
  return (
    <div>
      {isLoading ? (
        <div className="bg-white mt-5 pt-5 page-min-height">
          <div className="container rs-page">
            <div className="row">
              <Pane>
                <Spinner marginX="auto" marginY={120} />
              </Pane>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-5 mt-5" style={{ backgroundColor: "white" }}>
          <div className="row pb-5">
            <div className="col-9">
              <h1>Your Address List</h1>
            </div>
            <div className="col-3">
              <p>
                <button
                  type="button"
                  onClick={Add}
                  className="btn btn bg-edr-yellow text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                  {" Add New Address"}
                </button>
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {allAddress !== undefined && allAddress.length !== 0 ? (
                allAddress?.map((address, key) => {
                  return <AddressCard key={key} address={address} />;
                })
              ) : allAddress !== undefined && allAddress.length === 0 ? (
                <div className="bg-white">
                  <div
                    className="container py-5"
                    style={{ marginLeft: "50px" }}
                  >
                    <div
                      className="row text-center"
                      style={{ padding: "15px 0px" }}
                    >
                      <div className="col-md-12 float-left">
                        <h3 className="fw-bold">No Address Found</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrimaryAddressList;
