import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRunner } from "../../services/communicator";
import {
  deleteAddressJson,
  getAddressByIdJson,
  getAllAddressJson,
  getPrimaryAddressJson,
  postAddress,
  postPrimaryAddressJson,
  updateAddress,
} from "../../services/standard";
import { toasterService, toasterType } from "../../services/toasterService";

export const getAddressById = createAsyncThunk(
  "primaryAddress/getAddressById",
  async (obj) => {
    const getAddressJson = Object.assign({}, getAddressByIdJson);
    getAddressJson.url = updateAddress.url + "/" + obj.addressId;
    const response = await ApiRunner(getAddressJson);
    return response;
  }
);

export const getPrimaryAddress = createAsyncThunk(
  "primaryAddress/getPrimaryAddress",
  async (obj) => {
    const requestOptions = Object.assign({}, getPrimaryAddressJson);
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const getAllAddress = createAsyncThunk(
  "primaryAddress/getAllAddress",
  async (obj) => {
    const requestOptions = Object.assign({}, getAllAddressJson);
    const response = await ApiRunner(requestOptions);
    return response;
  }
);

export const editAddress = createAsyncThunk(
  "primaryAddress/updateAddress",
  async (obj) => {
    const editAddressJson = Object.assign({}, updateAddress);
    editAddressJson.url = updateAddress.url + "/" + obj.addressId;
    editAddressJson[`body`] = JSON.stringify(obj);
    const response = await ApiRunner(editAddressJson);
    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const addAddress = createAsyncThunk(
  "primaryAddress/postAddress",
  async (obj) => {
    const postAddressJson = Object.assign({}, postAddress);
    postAddressJson[`body`] = JSON.stringify(obj);
    const response = await ApiRunner(postAddressJson);
    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const setPrimary = createAsyncThunk(
  "primaryAddress/editAddress",
  async (obj) => {
    const setPrimaryAddressJson = Object.assign({}, postPrimaryAddressJson);
    setPrimaryAddressJson.url =
      postPrimaryAddressJson.url + "/" + obj.addressId;
    const response = await ApiRunner(setPrimaryAddressJson);
    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const deleteAddress = createAsyncThunk(
  "primaryAddress/deleteAddress",
  async (obj) => {
    const dropAddressJson = Object.assign({}, deleteAddressJson);
    dropAddressJson.url = deleteAddressJson.url + "/" + obj.addressId;
    const response = await ApiRunner(dropAddressJson);

    toasterService(
      response.status === true ? toasterType.success : toasterType.danger,
      response?.message
    );
    return response;
  }
);

export const addressSlice = createSlice({
  name: "primaryAddress",
  initialState: {
    status: "idle",
    result: {
      data: {},
    },
    resultb: {
      data: [{}],
    },
    resultbyid: {
      data: {},
    },
    primaryAddressLat: 0.0,
    primaryAddressLong: 0.0,
    counter: 0,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPrimaryAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPrimaryAddress.fulfilled, (state, action) => {
        state.status = "complete";
        if (action.payload?.data && "address" in action.payload?.data) {
          if (action.payload?.data?.address?.length !== 0) {
            state.primaryAddressLat = action.payload.data.address[0].latitude;
            state.primaryAddressLong = action.payload.data.address[0].longitude;
          }
        }

        state.result = action.payload;
      })
      .addCase(getAllAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllAddress.fulfilled, (state, action) => {
        state.status = "complete";
        state.resultb = action.payload;
      })
      .addCase(getAddressById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAddressById.fulfilled, (state, action) => {
        state.status = "complete";
        state.resultbyid = action.payload;
      })
      .addCase(setPrimary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setPrimary.fulfilled, (state, action) => {
        state.status = "complete";
        const stateObj = {
          status: action.payload.status,
          message: action.payload.message,
          data: {
            _id: action.payload.data._id,
            address: [
              action.payload.data.address.find(
                (address) => address.primary === true
              ),
            ],
          },
        };
        state.counter += 1;
        state.result = stateObj;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAddress.fulfilled, (state) => {
        state.counter += 1;
      })
      .addCase(addAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAddress.fulfilled, (state) => {
        state.counter += 1;
      });
  },
});

export const selectPrimaryAddress = (state) => state.primaryAddress;
export const selectAllAddress = (state) => state.primaryAddress;
export const selectAddressById = (state) => state.primaryAddress;
export const selectedPrimaryAddressLat = (state) =>
  state.primaryAddress.primaryAddressLat;
export const selectedPrimaryAddressLong = (state) =>
  state.primaryAddress.primaryAddressLong;
export const currentCounterValue = (state) => state.primaryAddress.counter;
export default addressSlice.reducer;
