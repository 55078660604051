import { Dialog, Pane, Spinner } from "evergreen-ui";
import * as $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FloatingCartComponent from "../components/FloatingCartComponent";
import Product from "../components/Product";
import {
  selectedPrimaryAddressLat,
  selectedPrimaryAddressLong,
  selectPrimaryAddress,
} from "../features/restaurant/addressSlice";
import { setIsPopupModal } from "../features/restaurant/cartSlice";
import { getCartCountASyncThunk } from "../features/restaurant/headerSlice";
import {
  addCart,
  selectProduct,
  setIsClearCart,
} from "../features/restaurant/restaurantSlice";
import {
  getGuestSearchProduct,
  getSearchProduct,
  selectSearchProduct,
} from "../features/restaurant/searchSlice";
import {
  getLat,
  getLong,
  getSearchKeyword,
  getToken,
  notLoggedInErrorMessage,
  removeSearchKeyword,
  setPageUrl,
  setSearchKeyword,
} from "../services/standard";
import { toasterService, toasterType } from "../services/toasterService";
window["jQuery"] = window["$"] = $;

const ProductSearch = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState(" Products Found");
  const addressModalReference = useRef();
  const recordPerPage = 10;
  const pageRange = 10;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (token) {
      if (getSearchKeyword()) {
        setSearchText(" Product Found For");
        dispatch(
          getSearchProduct({
            lat: primaryAddressLat,
            long: primaryAddressLong,
            name: getSearchKeyword(),
            pageno: currentPage,
          })
        );
      } else {
        dispatch(
          getSearchProduct({
            lat: address?.address[0]?.latitude,
            long: address?.address[0]?.longitude,
            name: search,
            pageno: pageNumber,
          })
        );
      }
    } else {
      if (getLat() && getLong()) {
        dispatch(
          getGuestSearchProduct({
            lat: getLat(),
            long: getLong(),
            name: search,
            pageno: pageNumber,
          })
        );
      } else {
        function showPosition(position) {
          dispatch(
            getGuestSearchProduct({
              lat: position?.coords?.latitude,
              long: position?.coords?.longitude,
              name: search,
              pageno: pageNumber,
            })
          );
        }
        navigator?.geolocation?.getCurrentPosition(
          showPosition,
          (positionError) => {
            if (positionError) {
              toasterService(
                toasterType.danger,
                positionError?.message +
                  ". Please manually allow location in your browser."
              );
            }
          },
          { enableHighAccuracy: true }
        );
      }
    }
  };

  const [isLoading, setLoading] = useState(false);
  const selectPrimaryAddressApiData = useSelector(selectPrimaryAddress);
  const selectPrimaryAddressApi = selectPrimaryAddressApiData.result;
  const address = selectPrimaryAddressApi.data;
  const selectSearchValueData = useSelector(selectSearchProduct);
  const selectSearchValue = selectSearchValueData.result;
  const addCartData = useSelector(selectProduct);
  const addCartValue = addCartData.resultAddCart;
  const isClearCart = addCartData.isClearCart;
  const [isShown, setIsShown] = useState(false);
  const [currentLat, setCurrentLat] = useState(false);
  const [currentLong, setCcurrentLong] = useState(false);
  const primaryAddressLat = useSelector(selectedPrimaryAddressLat);
  const primaryAddressLong = useSelector(selectedPrimaryAddressLong);
  const statePopUp = useSelector((state) => state?.cart?.popupState);
  const navigate = useNavigate();
  useEffect(() => {
    $(document).ready(function () {
      if (statePopUp === true) {
        $(addressModalReference.current).modal("show");
      }
      $("#exampleModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  }, [statePopUp]);

  function goto() {
    $(addressModalReference.current).modal("hide");
    navigate("/sign-in");
  }

  function cancel() {
    $(addressModalReference.current).modal("hide");
    dispatch(setIsPopupModal(false));
  }

  const dispatch = useDispatch();
  const token = getToken();

  useEffect(() => {
    const currentURL = window.location.href;
    const forId = currentURL.split("/").slice(-1)[0];
    if (forId === "") {
      const urlCurrentPage = ``;
      setPageUrl(urlCurrentPage);
    } else {
      const urlCurrentPage = `${forId}`;
      setPageUrl(urlCurrentPage);
    }
    function showPosition(position) {
      setCurrentLat(position?.coords?.latitude);
      setCcurrentLong(position?.coords?.longitude);
    }
    setLoading(true);
    navigator?.geolocation?.getCurrentPosition(
      showPosition,
      (positionError) => {
        if (positionError) {
          toasterService(
            toasterType.danger,
            positionError?.message +
              ". Please manually allow location in your browser."
          );
        }
      },
      { enableHighAccuracy: true }
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      selectSearchValueData?.status === "complete" &&
      selectPrimaryAddressApiData?.status === "complete"
    ) {
      setLoading(false);
    }
  }, [selectSearchValueData?.status, selectPrimaryAddressApiData?.status]);

  useEffect(() => {
    const currentURL = window.location.href;
    const forPage = currentURL.split("/").slice(-1)[0];
    if (forPage === "restaurant-search") {
      if (token && primaryAddressLat && primaryAddressLong) {
        setLoading(true);
        if (getSearchKeyword()) {
          setSearchText(" Product Found For");
          dispatch(
            getSearchProduct({
              lat: primaryAddressLat,
              long: primaryAddressLong,
              name: getSearchKeyword(),
              pageno: currentPage,
            })
          );
        } else {
          dispatch(
            getSearchProduct({
              lat: primaryAddressLat,
              long: primaryAddressLong,
              name: search,
              pageno: currentPage,
            })
          );
        }
      } else {
        setLoading(false);
        if (getLat() && getLong()) {
          if (getSearchKeyword()) {
            setSearchText(" Product Found For");
            dispatch(
              getGuestSearchProduct({
                lat: getLat(),
                long: getLong(),
                name: getSearchKeyword(),
                pageno: currentPage,
              })
            );
          } else {
            setSearchText(" Product Found For");
            dispatch(
              getGuestSearchProduct({
                lat: getLat(),
                long: getLong(),
                name: "",
                pageno: currentPage,
              })
            );
          }
        } else {
          setLoading(true);
          navigator?.geolocation?.getCurrentPosition(
            showPosition,
            (positionError) => {
              if (positionError) {
                toasterService(
                  toasterType.danger,
                  positionError?.message +
                    ". Please manually allow location in your browser."
                );
              }
            },
            { enableHighAccuracy: true }
          );
        }
      }
      setLoading(false);
    }
  }, [
    currentPage,
    primaryAddressLat,
    primaryAddressLong,
    dispatch,
    selectPrimaryAddressApi.data?.address,
    token,
  ]);

  async function showPosition(position) {
    setLoading(true);
    if (search === "") {
      removeSearchKeyword();
      setSearch("");
    } else {
      setSearchKeyword(search);
      setSearch(search);
    }
    await dispatch(
      getGuestSearchProduct({
        lat: position?.coords?.latitude,
        long: position?.coords?.longitude,
        name: search,
        pageno: currentPage,
      })
    );
    await setLoading(false);
  }

  useEffect(() => {
    if (isClearCart) {
      setIsShown(true);
    }
  }, [isClearCart]);

  function clearCart(clear) {
    dispatch(
      addCart({
        product_id: addCartValue?.payload?.product_id,
        quantity: 1,
        shop_id: addCartValue?.payload?.shop_id,
        is_clear: clear,
      })
    );
    dispatch(getCartCountASyncThunk());
    if (token) {
      setLoading(false);
      dispatch(
        getSearchProduct({
          lat: selectPrimaryAddressApi.data?.address[0]?.latitude,
          long: selectPrimaryAddressApi.data?.address[0]?.longitude,
          name: search,
          pageno: currentPage,
        })
      );
    } else {
      if (getLat() && getLong()) {
        dispatch(
          getGuestSearchProduct({
            lat: getLat(),
            long: getLong(),
            name: search,
            pageno: currentPage,
          })
        );
      } else {
        setLoading(true);
        navigator?.geolocation?.getCurrentPosition(
          showPosition,
          (positionError) => {
            if (positionError) {
              toasterService(
                toasterType.danger,
                positionError?.message +
                  ". Please manually allow location in your browser."
              );
            }
          },
          { enableHighAccuracy: true }
        );
      }
    }
  }

  async function findRestaurants() {
    await setLoading(true);
    if (
      token &&
      address?.address[0]?.latitude &&
      address?.address[0]?.longitude
    ) {
      if (search === "") {
        await removeSearchKeyword();
        await setSearch("");
      } else {
        await setSearchKeyword(search);
        await setSearch(search);
      }
      await dispatch(
        getSearchProduct({
          lat: address?.address[0]?.latitude,
          long: address?.address[0]?.longitude,
          name: search,
          pageno: currentPage,
        })
      );
    } else {
      if (search === "") {
        await removeSearchKeyword();
        await setSearchText(" Product Found");
      } else {
        await setSearchKeyword(search);
      }
      if (getLat() && getLong()) {
        await dispatch(
          getGuestSearchProduct({
            lat: getLat(),
            long: getLong(),
            name: search,
            pageno: currentPage,
          })
        );
      } else {
        setLoading(true);
        navigator?.geolocation?.getCurrentPosition(
          showPosition,
          (positionError) => {
            if (positionError) {
              toasterService(
                toasterType.danger,
                positionError?.message +
                  ". Please manually allow location in your browser."
              );
            }
          },
          { enableHighAccuracy: true }
        );
      }
    }
    await setLoading(false);
  }

  const handleKeyPress = async (event) => {
    await setLoading(true);
    if (event.key === "Enter") {
      await setSearchKeyword(search);
      if (
        token &&
        address?.address[0]?.latitude &&
        address?.address[0]?.longitude
      ) {
        await setSearch(search);
        dispatch(
          await getSearchProduct({
            lat: address?.address[0]?.latitude,
            long: address?.address[0]?.longitude,
            name: search,
            pageno: currentPage,
          })
        );
      } else {
        if (getLat() && getLong()) {
          await dispatch(
            getGuestSearchProduct({
              lat: getLat(),
              long: getLong(),
              name: search,
              pageno: currentPage,
            })
          );
        } else {
          setLoading(true);
          navigator?.geolocation?.getCurrentPosition(
            showPosition,
            (positionError) => {
              console.log(
                "🚀 ~ file: ProductSearch.js ~ line 384 ~ handleKeyPress ~ positionError",
                positionError
              );
              if (positionError) {
                toasterService(
                  toasterType.danger,
                  positionError?.message +
                    ". Please manually allow location in your browser."
                );
              }
            },
            { enableHighAccuracy: true }
          );
        }
      }
    }
    await setLoading(false);
  };

  function setCartStatus() {
    dispatch(setIsClearCart(false));
  }

  return (
    <div>
      <div className="bg-white mt-5 pt-5 page-min-height">
        <div className="container">
          <div className="row">
            <div className="col-md-11 float-left">
              <div className="row">
                <div className="col-12 m-3 fs-4">
                  <div className="buttonIn">
                    <input
                      type="text"
                      className="searchbox"
                      placeholder="Search"
                      id="search"
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />
                    <span
                      className="btn btn bg-edr-yellow text-white search-btn fw-bold fs-4"
                      onClick={findRestaurants}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <Pane>
                  <Spinner marginX="auto" marginY={120} />
                </Pane>
              ) : selectSearchValue.status === false ? (
                <div className="bg-white">
                  <div className="container py-5">
                    <div
                      className="row text-center"
                      style={{ padding: "100px 0px" }}
                    >
                      <div className="col-md-12 float-left">
                        <h3 className="fw-bold">No Product Found</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white">
                  <div className="container pt-5">
                    <div className="row">
                      <div className="col-md-11 float-left">
                        {selectSearchValue.data[0]?.totalCount?.total_rows >
                        0 ? (
                          <h3 className="fw-bold">
                            {selectSearchValue.data[0]?.totalCount?.total_rows >
                            1
                              ? `${selectSearchValue.data[0]?.totalCount?.total_rows} Products Found `
                              : `${selectSearchValue.data[0]?.totalCount?.total_rows} Product Found `}
                            {getSearchKeyword() === null
                              ? ``
                              : `for ${getSearchKeyword()}`}
                          </h3>
                        ) : (
                          <h3 className="fw-bold">
                            {selectSearchValue.data[0]?.totalCount?.total_rows}
                            Search for products
                          </h3>
                        )}
                      </div>
                    </div>
                    <div className="row mt-5">
                      {selectSearchValue?.data[0] !== undefined &&
                      selectSearchValue?.status !== false
                        ? selectSearchValue?.data[0].data?.map(
                            (product, key) => {
                              return (
                                <Product
                                  key={key}
                                  productName={product?.generic_products?.name}
                                  shopName={product?.shop_info?.name}
                                  isOpen={product?.shop_info?.is_open}
                                  shopAddress={
                                    product?.shop_info?.address?.area
                                  }
                                  productImage={
                                    product?.generic_products?.product_image
                                  }
                                  price={product?.price}
                                  offerPrice={product?.final_price}
                                  productId={product?._id}
                                  shopId={product?.shop_info?._id}
                                  cartQuantity={product?.cart_quantity}
                                  paramValue={search}
                                  outOfStock={product?.out_of_stock}
                                  discounted={product?.discount}
                                  discount={product?.discount}
                                  discountType={product?.discount_type}
                                  isDeliverable={product?.isDeliverable}
                                  distance={product?.distance}
                                  search={search}
                                  searchPageNo={currentPage}
                                  lat={token ? primaryAddressLat : currentLat}
                                  long={
                                    token ? primaryAddressLong : currentLong
                                  }
                                />
                              );
                            }
                          )
                        : ""}
                    </div>
                    {/* <div className="row mt-3 pb-5">
                      <div className="col-md-12 d-flex justify-content-center flex-row">
                        <Pagination page={1} totalPages={5}></Pagination>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Pane>
          <Dialog
            isShown={isShown}
            title="Cart is not empty"
            confirmLabel="Clear Cart"
            onConfirm={(close) => {
              close();
              clearCart(true);
            }}
            onCloseComplete={() => {
              setIsShown(false);
              setCartStatus();
            }}
          >
            <span className="fs-6">{addCartValue?.message}</span>
          </Dialog>
        </Pane>
      </div>

      <div className="bg-white pt-5">
        <div className="col-md-12 d-flex justify-content-center flex-row">
          {Object.keys(selectSearchValue.data).length !== 0 ? (
            <Pagination
              itemClass="page-item" // add it for bootstrap 4
              linkClass="page-link" // add it for bootstrap 4
              activePage={currentPage}
              itemsCountPerPage={recordPerPage}
              totalItemsCount={
                selectSearchValue.data[0]?.totalCount?.total_rows
              }
              pageRangeDisplayed={pageRange}
              onChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <FloatingCartComponent />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={addressModalReference}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body fs-3">{notLoggedInErrorMessage}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(close) => {
                  cancel();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn secondary-bg-color text-white"
                onClick={(close) => {
                  goto();
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductSearch;
