import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Category from "./Category";
import RestaurantCard from "./RestaurantCard";

const NearByComponent = (props) => {
  const {
    previousHorizontalCategoryBar,
    horizontalCategoryBarReference,
    selectedNearbyShops,
    nextHorizontalCategoryBar,
  } = props;
  return (
    <div className="bg-white bg-green">
      <div className="container-fluid">
        <div className="row">
          {selectedNearbyShops?.data?.top_categories?.length > 0 ? (
            <>
              <div className="col-lg-1 col-md-1 catButtonContainer">
                <button
                  type="button"
                  className="btn btn-warning m-5"
                  onClick={previousHorizontalCategoryBar}
                >
                  <HiOutlineChevronLeft size={35} color="white" />
                </button>
              </div>
              <div
                className="col-lg-10 col-md-10 d-flex flex-row overflowX"
                ref={horizontalCategoryBarReference}
              >
                {selectedNearbyShops?.data?.top_categories ? (
                  selectedNearbyShops?.data?.top_categories?.map(
                    (category, key) => {
                      return <Category key={key} {...category} />;
                    }
                  )
                ) : (
                  <> </>
                )}
              </div>
              <div className="col-lg-1 col-md-1 catButtonContainer">
                <button
                  type="button"
                  className="btn btn-warning m-5"
                  onClick={nextHorizontalCategoryBar}
                >
                  <HiOutlineChevronRight size={35} color="white" />
                </button>
              </div>
            </>
          ) : (
            <> </>
          )}

          {selectedNearbyShops?.data.near_by_restaurants?.length > 0 ? (
            <div className="row mt-5">
              <div className="col-8 text-left">
                <h3 className="fw-bold">Restaurants Near You</h3>
              </div>
              <div className="col-4 text-end">
                <Link
                  className="navbar-brand col-md-7 SeeAllHover px-2"
                  to="/SeeAllRestaurent"
                >
                  {" "}
                  See All
                </Link>
              </div>
            </div>
          ) : (
            <> </>
          )}
        </div>

        <div className="row mt-5 d-flex justify-content-center">
          {selectedNearbyShops?.data.near_by_restaurants?.length > 0 ? (
            selectedNearbyShops?.data.near_by_restaurants?.map((shop, key) => {
              return (
                <RestaurantCard
                  key={key}
                  name={shop?.name}
                  id={shop?._id}
                  isOpen={shop?.is_open}
                  isDeliverable={shop?.isDeliverable}
                  address={`${shop?.address?.house}, ${shop?.address?.area}, ${shop?.address?.locality}, ${shop?.address?.city}`}
                  cuisines={shop?.cuisine}
                  rating={shop?.total_rating}
                  distance={shop?.distance}
                  shopImage={shop?.shop_image}
                  lat={props?.lat}
                  long={props?.long}
                />
              );
            })
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NearByComponent;
